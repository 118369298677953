// 
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change Around background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;


$gray-800: #32344A !default;
$primary: #D1050C !default;
$warning: $gray-800 !default;
$dark: $gray-800 !default;