// 
// user.scss
// Use this to write your custom SCSS
//

.ra-gewicht {
	background-image: url(ra-gewicht.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	width: 24px;
}

.ra-gewicht-dark {
	background-image: url(ra-gewicht-dark.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	width: 24px;
}